<template>
    <div>
    <lottie-animation
        :json="path"
        :loop="loop"
        :id="id"
        renderer="svg"
        :loopDelayMin="loopDelayMin"
    />
    </div>
</template>

<script>

import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
    name: "Animation",

    components: {
        LottieAnimation
    },

    props: {
        path: {
            type: String,
            required: true
        },
        loop: {
            type: Boolean,
            default: true
        },
        id: {
            type: String,
            required: true
        },
        loopDelayMin: {
            type: Number,
            default: 0
        }
    }
}
</script>

<style scoped>

</style>
