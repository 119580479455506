require('./bootstrap');

import Vue from 'vue'

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)

Vue.component("nav-bar", () => import("./components/NavBar"));
Vue.component("home-service-carousel", () => import("./components/home/ServicesCarousel"));
Vue.component("home-hero", () => import("./components/home/Hero"));
Vue.component("home-logo-carousel", () => import("./components/home/LogosCarousel"));
Vue.component("hero", () => import("./components/layout/Hero"));
Vue.component("newsletter", () => import("./components/home/Newsletter"));
Vue.component("promo", () => import("./components/home/Promo"));
Vue.component("services", () => import("./components/services/ServicesLayout"));
Vue.component("faqs", () => import("./components/Faqs"));
Vue.component('cookie-bar', require('./components/CookieBar.vue').default);
Vue.component('animation', require('./components/Animation.vue').default);

export const bus = new Vue();

//Main pages
const app = new Vue({
    el: '#app',
});
