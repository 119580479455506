<template>
    <footer>
        <cookie-law theme="dark-lime"
                    buttonText="Continua"
        >
            <div slot="message">
                Il nostro sito web utilizza cookie per garantirti una migliore esperienza di navigazione e cookie che rientrano nella responsabilità di terze parti. Proseguendo nella navigazione acconsenti all’utilizzo dei cookie.
                Se desideri maggiori informazioni sui cookie o negare il consenso ai cookie di terze parti accedi alla nostra <a href="/it/privacy-policy" style="color: #00bd9d !important">Privacy Policy</a>.
            </div>
        </cookie-law>
    </footer>
</template>

<script>
    import CookieLaw from 'vue-cookie-law'
    export default {
        components: { CookieLaw }
    }
</script>
